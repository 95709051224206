<template>
  <div class="osahan-profile">
    <!-- profile -->
    <div class="container position-relative">
      <div class="py-5 osahan-profile row">
        <user-sidebar  v-on:showAdd="showAdd" />
        <div class="col-md-8 mb-3">
          <div class="rounded shadow-sm p-4 bg-white">
            <h5 class="mb-4">My account</h5>
            <div id="edit_profile">
              <form ref="myForm">
                <div>
                  <div class="form-group">
                    <label for="exampleInputName1">Full Name</label>
                    <input
                      placeholder="Enter you full name"
                      required
                      v-model="editUser.user_name"
                      type="text"
                      class="form-control"
                      id="exampleInputName1d"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputNumber1">Mobile Number</label>
                    <input
                      placeholder="Enter your mobile number"
                      required
                      v-model="editUser.phone"
                      type="tel"
                      
                      class="form-control"
                      id="exampleInputNumber1"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Email</label>
                    <input
                    placeholder="Enter your email address"
                      required
                      v-model="editUser.email"
                      type="email"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      class="form-control"
                      id="exampleInputEmail1"
                    />
                  </div>
                  <div class="text-center">
                    <a
                      v-on:click="saveChanges"
                      class="btn btn-primary btn-block"
                    >
                      Save Changes
                    </a>
                  </div>
                </div>
              </form>
              <div class="additional">
                <div class="change_password my-3">
                  <a
                    href="change_password"
                    class="
                      p-3
                      border
                      rounded
                      bg-white
                      btn
                      d-flex
                      align-items-center
                    "
                    >Change Password <i class="feather-arrow-right ml-auto"></i
                  ></a>
                </div>
                <!-- <div class="deactivate_account">
                  <a
                    href="forgot_password"
                    class="
                      p-3
                      border
                      rounded
                      bg-white
                      btn
                      d-flex
                      align-items-center
                    "
                    >Deactivate Account
                    <i class="feather-arrow-right ml-auto"></i
                  ></a>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modals -->
    <add-address-modal v-on:addressAdded="$router.push('success_save')" :addModalShow="addModalShow" />
  </div>
</template>
<script>
import AddAddressModal from "../components/addAddressModal.vue";
import userSidebar from "../components/userSidebar.vue";
export default {
  components: { userSidebar, AddAddressModal },
  data() {
    return {
      addModalShow: false,
      user: null,
      editUser: { user_name: null, phone: null, email: null },
    };
  },
  watch: {
    user(value) {
      this.editUser = value.user;
    },
  },
  mounted() {
    this.user = JSON.parse(this.$store.state.curUser);
  },
  methods: {
    showAdd() {
      if (this.addModalShow) this.addModalShow = false;
      else this.addModalShow = true;
    },
    saveChanges() {
        if(confirm('are you sure?')){
      var BreakException = {};
      try {
        this.$refs.myForm.forEach((element) => {
          if (
            element.tagName.toLowerCase() == "input" ||
            element.tagName.toLowerCase() == "textarea"
          ) {
            if (!element.validity.valid) {
              element.focus();
              throw BreakException;
            }
          }
        });

        let url =
          this.$store.state.baseURL +
          `/api/profile/update?name=${this.editUser.user_name}&phone=${this.editUser.phone}&email=${this.editUser.email}&api_token=${this.user.token}`;
        fetch(url, {
          method: "POST",
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              console.log(
                "Server returned " +
                  response.status +
                  " : " +
                  response.statusText
              );
            }
          })
          .then((response) => {
            if(response.response){
            this.setCurUser(response.data);
            }else{
              if(response.message)
                alert(response.message)
              if(response.messages){
                alert(response.messages)
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (error == BreakException) console.log("break");
      }
        }
    },
    setCurUser(result) {
      //console.log(result);
      this.$store.commit("setUser", result);
      window.localStorage.setItem("jwt", result.token);
      window.localStorage.setItem("curUser", JSON.stringify(result));
      this.$router.push("success_save");
    },
  },
};
</script>